import React, { useState, useRef } from 'react'
import 'aframe'
import { useEffect } from 'react'
import './App.css'
import Logo from './logo.svg'

const App = () => {
  const videoRef = useRef(null)
  const cameraRef = useRef(null)
  const starterRef = useRef(null)
  const [muted, setMuted] = useState(true)
  const [ready, setReady] = useState(false)

  useEffect(() => {
    starterRef.current.addEventListener('click', () => {
      if (!ready) return
      starterRef.current.style.display = 'none'
      videoRef.currentTime = 0
      videoRef.current.play()
    })
  }, [ready])

  useEffect(() => {
    videoRef.current.addEventListener('canplaythrough', () => {
      setReady(true)
    })
    // setTimeout(() => {
    //   setReady(true)
    // }, 5000)
  }, [])

  const handleOverlayClick = () => {
    setMuted(!muted)
  }

  return (
    <a-scene>
      <a-videosphere
        rotation='0 180 0'
        src='#video'
        play-on-window-click
        play-on-vrdisplayactivate-or-enter-vr
      ></a-videosphere>

      <a-camera
        user-height='0'
        wasd-controls-enabled='false'
        arrow-key-rotation
        ref={cameraRef}
      ></a-camera>
      <div className='starter' ref={starterRef}>
        <img src={Logo} alt='logo' className='logo' />
        {ready ? 'Click here to start the video' : 'Loading video, please wait'}
      </div>

      <a-assets timeout='30000'>
        <video
          id='video'
          style={{ display: 'none' }}
          autoPlay
          loop
          crossOrigin='anonymous'
          preload='auto'
          muted={muted}
          playsInline
          webkit-playsinline='true'
          ref={videoRef}
        >
          <source
            type='video/mp4'
            src={'https://cdn.homedefense.it/2024-02-24.mp4'}
          />
        </video>
      </a-assets>
      <div className='overlay' onClick={() => handleOverlayClick()}>
        <i
          className={
            muted ? 'fa-solid fa-volume-xmark' : 'fa-solid fa-volume-high'
          }
        ></i>
      </div>
    </a-scene>
  )
}

export default App
